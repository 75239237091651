import React, { useState, useEffect } from 'react';
import './styles/styles.css';
import useWindowDimensions from './hooks/useWindowDimensions';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import Contact from './components/Contact';
import { useGlobalState } from './providers/globalState';
import Modal from './components/Modal';
import ImageCarousel from './components/ImageCarousel';

const firebaseConfig = {
  apiKey: "AIzaSyD-WZ3rBlgfeLnYfjzynNqPgzVHJfx6RMA",
  authDomain: "jind-d9e77.firebaseapp.com",
  projectId: "jind-d9e77",
  storageBucket: "jind-d9e77.appspot.com",
  messagingSenderId: "692147497941",
  appId: "1:692147497941:web:a87d36b9509710d7d56b5c",
  measurementId: "G-LD6D2MRV37"
};

const app = initializeApp(firebaseConfig);
const database = getFirestore(app);

export default function App() {
  const [shouldRenderNavbar, setShouldRenderNavbar] = useState(false);
  const [displayProperty, setDisplayProperty] = useState(true);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const {width, height} = useWindowDimensions();
  const [futureEvents, setFutureEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const {emailStatusModalOpen, emailStatusModalMessage} = useGlobalState();
  const monthsDictionary = {
    '01': 'Ianuarie',
    '02': 'Februarie',
    '03': 'Martie',
    '04': 'Aprilie',
    '05': 'Mai',
    '06': 'Iunie',
    '07': 'Iulie',
    '08': 'August',
    '09': 'Septembrie',
    '10': 'Octombrie',
    '11': 'Noiembrie',
    '12': 'Decembrie',
  }

  function iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  }

  useEffect(() => {
    const logoAnimationTimeout = setTimeout(() => {
      const loadingImageContainer = document.querySelector("#loading-image-container");
      loadingImageContainer.remove();
    }, 4050);

    document.querySelector('#body-container').scroll({top: 0, left: 0, behavior: 'smooth'});
    (async () => {
      const futureEventsSnapshot = await getDocs(collection(database, 'futureEvents'));
      let futureEventsList = [];
      futureEventsSnapshot.forEach((document) => {
        futureEventsList = [...futureEventsList, {...document.data(), dateValue: Date.parse(document.data().date)}];
      });
      futureEventsList.forEach((futureEvent) => {
        const futureEventDate = futureEvent.date.split('-');
        futureEvent.date = `${futureEventDate[2]}\n${monthsDictionary[futureEventDate[1]]}\n${futureEventDate[0]}`;
      });
      futureEventsList.sort((firstEvent, secondEvent) => firstEvent.dateValue - secondEvent.dateValue);
      setFutureEvents(futureEventsList);

      const pastEventsSnapshot = await getDocs(collection(database, 'pastEvents'));
      let pastEventsList = [];
      pastEventsSnapshot.forEach((document) => {
        pastEventsList = [...pastEventsList, {...document.data(), dateValue: Date.parse(document.data().date)}];
      });
      pastEventsList.forEach((pastEvent) => {
        const pastEventDate = pastEvent.date.split('-');
        pastEvent.date = `${pastEventDate[2]}\n${monthsDictionary[pastEventDate[1]]}\n${pastEventDate[0]}`;
      });
      pastEventsList.sort((firstEvent, secondEvent) => firstEvent.dateValue - secondEvent.dateValue);
      setPastEvents(pastEventsList);
    })();

    return () => {
      logoAnimationTimeout && clearTimeout(logoAnimationTimeout);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let displayPropertyTimeout = setTimeout(() => {
      if (!shouldRenderNavbar) {
        setDisplayProperty(true);
      } else {
        setDisplayProperty(false);
      }
    }, 1000);
    return () => {
      displayPropertyTimeout && clearTimeout(displayPropertyTimeout);
    }
  }, [shouldRenderNavbar]);

  useEffect(() => {
    if (width > 900) {
      setMenuIsOpen(false);
    }
  }, [width]);

  const handleScroll = (e: React.UIEvent<HTMLElement>) => {
    if(e.currentTarget.scrollTop === 0) {
      setShouldRenderNavbar(false);
    } else {
      setShouldRenderNavbar(true);
    }
  }

  return (
    <div id='container'>
      <div id="loading-image-container">
        <img
          id="loading-image"
          src={require("./resources/JindLogoBackground.webp")}
          alt="Logo Jind"
        />
      </div>
      {
        menuIsOpen &&
          <>
            <div
              id='navigation-menu-background'
              onClick={() => setMenuIsOpen(!menuIsOpen)}
            />
          </>
      }
      <div className={`${menuIsOpen ? 'navigation-menu navigation-menu-visible' : 'navigation-menu'}`}>
        <div
          className='navigation-button'
          onClick={() => {
            const container = document.querySelector('#body-container');
            const element = document.querySelector('#section-about-us');
            const yPosition = element.getBoundingClientRect().top + container.scrollTop - 100;
            container.scroll({
              top: yPosition,
              left: 0,
              behavior: 'smooth'
            });
            setMenuIsOpen(!menuIsOpen);
          }}
        >
          About Us
        </div>
        <div
          className='navigation-button'
          onClick={() => {
            const container = document.querySelector('#body-container');
            const element = document.querySelector('#section-releases');
            const yPosition = element.getBoundingClientRect().top + container.scrollTop - 100;
            container.scroll({
              top: yPosition,
              left: 0,
              behavior: 'smooth'
            });
            setMenuIsOpen(!menuIsOpen);
          }}
        >
          Releases
        </div>
        <div
          className='navigation-button'
          onClick={() => {
            const container = document.querySelector('#body-container');
            const element = document.querySelector('#section-events');
            const yPosition = element.getBoundingClientRect().top + container.scrollTop - 100;
            container.scroll({
              top: yPosition,
              left: 0,
              behavior: 'smooth'
            });
            setMenuIsOpen(!menuIsOpen);
            (async () => {
              const futureEventsSnapshot = await getDocs(collection(database, 'futureEvents'));
              let futureEventsList = [];
              futureEventsSnapshot.forEach((document) => {
                futureEventsList = [...futureEventsList, {...document.data(), dateValue: Date.parse(document.data().date)}];
              });
              futureEventsList.forEach((futureEvent) => {
                const futureEventDate = futureEvent.date.split('-');
                futureEvent.date = `${futureEventDate[2]}\n${monthsDictionary[futureEventDate[1]]}\n${futureEventDate[0]}`;
              });
              futureEventsList.sort((firstEvent, secondEvent) => firstEvent.dateValue - secondEvent.dateValue);
              setFutureEvents(futureEventsList);

              const pastEventsSnapshot = await getDocs(collection(database, 'pastEvents'));
              let pastEventsList = [];
              pastEventsSnapshot.forEach((document) => {
                pastEventsList = [...pastEventsList, {...document.data(), dateValue: Date.parse(document.data().date)}];
              });
              pastEventsList.forEach((pastEvent) => {
                const pastEventDate = pastEvent.date.split('-');
                pastEvent.date = `${pastEventDate[2]}\n${monthsDictionary[pastEventDate[1]]}\n${pastEventDate[0]}`;
              });
              pastEventsList.sort((firstEvent, secondEvent) => firstEvent.dateValue - secondEvent.dateValue);
              setPastEvents(pastEventsList);
            })();
          }}
        >
          Events
        </div>
        <div
          className='navigation-button'
          onClick={() => {
            const container = document.querySelector('#body-container');
            const element = document.querySelector('#section-merchandise');
            const yPosition = element.getBoundingClientRect().top + container.scrollTop - 100;
            container.scroll({
              top: yPosition,
              left: 0,
              behavior: 'smooth'
            });
            setMenuIsOpen(!menuIsOpen);
          }}
        >
          Merchandise
        </div>
        <div
          className='navigation-button'
          onClick={() => {
            const container = document.querySelector('#body-container');
            const element = document.querySelector('#section-contact');
            const yPosition = element.getBoundingClientRect().top + container.scrollTop - 100;
            container.scroll({
              top: yPosition,
              left: 0,
              behavior: 'smooth'
            });
            setMenuIsOpen(!menuIsOpen);
          }}
        >
          Contact
        </div>
      </div>
      <img
        src={require('./resources/JindLogoBackground.webp')}
        alt='Jind'
        className={`
          ${(shouldRenderNavbar || menuIsOpen) ? 'visibility-animation-on navbar-display-flex' : (displayProperty ? 'visibility-animation-off navbar-display-none' : 'visibility-animation-off navbar-display-flex')}
        `}
        id='navbar-logo'
        onClick={() => {
          document.querySelector('#body-container').scroll({top: 0, left: 0, behavior: 'smooth'});
          setMenuIsOpen(false);
        }}
      />
      <div
        id="navbar"
        className={`
        ${(shouldRenderNavbar || menuIsOpen) ? 'visibility-animation-on navbar-display-flex' : (displayProperty ? 'visibility-animation-off navbar-display-none' : 'visibility-animation-off navbar-display-flex')}
        `}
      >
        {
          width > 900 ?
            <div
              id='navbar-buttons-container'
              className={`
                ${(shouldRenderNavbar || menuIsOpen) ? 'visibility-animation-on navbar-display-flex' : (displayProperty ? 'visibility-animation-off navbar-display-none' : 'visibility-animation-off navbar-display-flex')}
              `}
            >
              <div
                className='navigation-button'
                onClick={() => {
                  document.querySelector('#body-container').scroll({top: height, left: 0, behavior: 'smooth'});
                }}
              >
                About Us
              </div>
              <div
                className='navigation-button'
                onClick={() => {
                  const container = document.querySelector('#body-container');
                  const element = document.querySelector('#section-releases');
                  const yPosition = element.getBoundingClientRect().top + container.scrollTop - 100;
                  container.scroll({
                    top: yPosition,
                    left: 0,
                    behavior: 'smooth'
                  });
                }}
              >
                Releases
              </div>
              <div
                className='navigation-button'
                onClick={() => {
                  const container = document.querySelector('#body-container');
                  const element = document.querySelector('#section-events');
                  const yPosition = element.getBoundingClientRect().top + container.scrollTop - 100;
                  container.scroll({
                    top: yPosition,
                    left: 0,
                    behavior: 'smooth'
                  });
                  (async () => {
                    const futureEventsSnapshot = await getDocs(collection(database, 'futureEvents'));
                    let futureEventsList = [];
                    futureEventsSnapshot.forEach((document) => {
                      futureEventsList = [...futureEventsList, {...document.data(), dateValue: Date.parse(document.data().date)}];
                    });
                    futureEventsList.forEach((futureEvent) => {
                      const futureEventDate = futureEvent.date.split('-');
                      futureEvent.date = `${futureEventDate[2]}\n${monthsDictionary[futureEventDate[1]]}\n${futureEventDate[0]}`;
                    });
                    futureEventsList.sort((firstEvent, secondEvent) => firstEvent.dateValue - secondEvent.dateValue);
                    setFutureEvents(futureEventsList);

                    const pastEventsSnapshot = await getDocs(collection(database, 'pastEvents'));
                    let pastEventsList = [];
                    pastEventsSnapshot.forEach((document) => {
                      pastEventsList = [...pastEventsList, {...document.data(), dateValue: Date.parse(document.data().date)}];
                    });
                    pastEventsList.forEach((pastEvent) => {
                      const pastEventDate = pastEvent.date.split('-');
                      pastEvent.date = `${pastEventDate[2]}\n${monthsDictionary[pastEventDate[1]]}\n${pastEventDate[0]}`;
                    });
                    pastEventsList.sort((firstEvent, secondEvent) => firstEvent.dateValue - secondEvent.dateValue);
                    setPastEvents(pastEventsList);
                  })();
                }}
              >
                Events
              </div>
              <div
                className='navigation-button'
                onClick={() => {
                  const container = document.querySelector('#body-container');
                  const element = document.querySelector('#section-merchandise');
                  const yPosition = element.getBoundingClientRect().top + container.scrollTop - 100;
                  container.scroll({
                    top: yPosition,
                    left: 0,
                    behavior: 'smooth'
                  });
                }}
              >
                Merchandise
              </div>
              <div
                className='navigation-button'
                onClick={() => {
                  const container = document.querySelector('#body-container');
                  const element = document.querySelector('#section-contact');
                  const yPosition = element.getBoundingClientRect().top + container.scrollTop - 100;
                  container.scroll({
                    top: yPosition,
                    left: 0,
                    behavior: 'smooth'
                  });
                }}
              >
                Contact Us
              </div>
            </div>
          :
            !iOS() ?
              <div
                id='navbar-buttons-container'
                className={`
                  ${(shouldRenderNavbar || menuIsOpen) ? 'visibility-animation-on navbar-display-flex' : (displayProperty ? 'visibility-animation-off navbar-display-none' : 'visibility-animation-off navbar-display-flex')} ${width <= 900 && 'navbar-align-items-end'}
                `}
              >
                <svg
                  className={`hamburger hamburgerRotate ${menuIsOpen && 'active'}`}
                  viewBox="0 0 100 100"
                  width="50"
                  onClick={() => setMenuIsOpen(!menuIsOpen)}
                >
                  <path
                    className="line top"
                    d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
                  />
                  <path
                    className="line middle"
                    d="m 30,50 h 40"
                  />
                  <path
                    className="line bottom"
                    d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
                  />
                </svg>
              </div>
            :
              null
        }
      </div>
      <div id='body-container' onScroll={handleScroll} className={`${menuIsOpen && 'no-scroll'}`}>
        <img
          src={require('./resources/JindCover.webp')}
          alt='Jind Cover'
          id='jind-cover'
          className={'disable-drag'}
        />
        <div id='scroll-down-container'>
          <img
            src={require('./resources/ScrollDown.gif')}
            alt='Scroll Down'
            id='scroll-down'
            className={`${shouldRenderNavbar ? 'scroll-down-visibility-animation-off' : 'scroll-down-visibility-animation-on'}`}
          />
        </div>
        <h1>
          Jind
        </h1>
        <div id='section-about-us'>
          <h2>
            About Us
          </h2>
          <p>
            Established in 2018, the black / death metal band JIND saw the light of day in Arad, in western Romania. Originally named "I OFF GOD", the band was founded by guitarist Tira together with drummer Riță and Clau on vocals. Soon Petri has entered the band, to add the blackened heart harmonies to the band's music. Late 2019 JIND is taking form through the arrival of Sâpcu.
          </p>
          <p>
            JIND music incorporates sounds from different metal subgenres as the band members have different backgrounds from an even broader genre spectrum, ranging from extreme metal to classical music, which adds another layer of tints to their music. The band musical influences are bands such as Emperor, Anathema, My Dying Bride, Katatonia, Rotting Christ, Hypocrisy etc…
          </p>
          <p>
            On the way to their first EP release "ÎN" the band is eager to grasp the public's attention with their dark and heavy sounds. Meanwhile the band is active in their country underground scene, sharing the stage together with bands like Nervosa (BR), Necrovile (RO), Terra Infernal (RO), Indignity (PL) and others.
          </p>
          <h2 id="subsection-members">
            Members
          </h2>
          <div className={`member-box ${width <= 900 && 'member-box-mobile-view'}`}>
            <img
              src={require('./resources/Tira.webp')}
              alt='Tira'
            />
            <p>
              Tira - Guitar
            </p>
          </div>
          <div className={`member-box ${width <= 900 && 'member-box-mobile-view'}`}>
            {
              width > 900 &&
                <p>
                  Rometal - Vocals
                </p>
            }
            <img
              src={require('./resources/Rometal.webp')}
              alt='Rometal'
            />
            {
              width <= 900 &&
                <p>
                  Rometal - Vocals
                </p>
            }
          </div>
          <div className={`member-box ${width <= 900 && 'member-box-mobile-view'}`}>
            <img
              src={require('./resources/Petri.webp')}
              alt='Petri'
            />
            <p>
              Petri - Guitar
            </p>
          </div>
          <div className={`member-box ${width <= 900 && 'member-box-mobile-view'}`}>
            {
              width > 900 &&
                <p>
                  Cristi - Bass
                </p>
            }
            <img
              src={require('./resources/Cristi.webp')}
              alt='Cristi'
            />
            {
              width <= 900 &&
                <p>
                  Cristi - Bass
                </p>
            }
          </div>
          <div className={`member-box ${width <= 900 && 'member-box-mobile-view'}`}>
            <img
              src={require('./resources/Rita.webp')}
              alt='Lupu'
            />
            <p>
              Riță - Drums
            </p>
          </div>
          <h2 id="subsection-former-members">
            Former Members
          </h2>
          <div className={`member-box ${width <= 900 && 'member-box-mobile-view'}`}>
            {
              width > 900 &&
                <p>
                  Clau - Vocals (R.I.P)
                </p>
            }
            <img
              src={require('./resources/Clau.webp')}
              alt='Clau'
            />
            {
              width <= 900 &&
                <p>
                  Clau - Vocals (R.I.P)
                </p>
            }
          </div>
        </div>
        <p className='divider divider-releases'>
          •
        </p>
        <div id='section-releases'>
          <h2>
            LISTEN TO OUR LATEST RELEASE
            <br/>
            "În Negura Tăcerii"
          </h2>
          <div
            id='latest-release-container'
          >
            <iframe
              width="640"
              height="360"
              src="https://www.youtube.com/embed/Z_lTqx7LcDU"
              title="JIND - În Negura Tăcerii"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <p className='divider divider-events'>
          •
        </p>
        <div id='section-events'>
          <h2>
            Future Events
          </h2>
          <div className='events-container'>
          {
            futureEvents.length === 0 ?
              <p id='no-future-events-message'>
                No future events...
              </p>
            :
              <ImageCarousel eventType={"future"} events={futureEvents} />
          }
          </div>
          <h2>
            Past Events
          </h2>
          <div className='events-container'>
          {
            pastEvents.length === 0 ?
              <p id='no-past-events-message'>
                No past events...
              </p>
            :
              <ImageCarousel eventType={"past"} events={pastEvents} />
          }
          </div>
        </div>
        <p className='divider divider-merchandise'>
          •
        </p>
        <div id='section-merchandise'>
          <h2>
            Merchandise
          </h2>
          <div id='merch-container'>
            <img
              src={require('./resources/MerchTricou1.webp')}
              alt='Tricou'
            />
          </div>
        </div>
        <p className='divider divider-contact'>
          •
        </p>
        <div id='section-contact'>
          {
            width <= 900 ?
              <div id='contact-container-mobile'>
                <h2>
                  Contact Us
                </h2>
                <Contact />
                <h2>
                  Phone Number
                </h2>
                <p>
                  0745 194 613
                </p>
                <h2>
                  Follow Us
                </h2>
                <div id='other-pages-container-mobile'>
                  <img
                    className='link-button'
                    src={require('./resources/FacebookButton.webp')}
                    alt='Facebook'
                    onClick={() => window.open("https://www.facebook.com/profile.php?id=100076147121435", "_blank", "noreferrer")} 
                  />
                  <img
                    className='link-button'
                    src={require('./resources/YoutubeButton.webp')}
                    alt='Youtube'
                    onClick={() => window.open("https://www.youtube.com/@JIND-ie7ce", "_blank", "noreferrer")}
                  />
                </div>
              </div>
            :
              <div id='contact-container'>
                <div id='contact-container-left-panel'>
                  <h2>
                    Contact Us
                  </h2>
                  <Contact />
                </div>
                <div id='contact-container-right-panel'>
                  <h2>
                    Phone Number
                  </h2>
                  <p>
                    0745 194 613
                  </p>
                  <h2>
                    Follow Us
                  </h2>
                  <div id='other-pages-container-mobile'>
                    <img
                      className='link-button'
                      src={require('./resources/FacebookButton.webp')}
                      alt='Facebook'
                      onClick={()=> window.open("https://www.facebook.com/profile.php?id=100076147121435", "_blank", "noreferrer")} 
                    />
                    <img
                      className='link-button'
                      src={require('./resources/YoutubeButton.webp')}
                      alt='Youtube'
                      onClick={() => window.open("https://www.youtube.com/@JIND-ie7ce", "_blank", "noreferrer")}
                    />
                  </div>
                </div>
              </div>
          }
        </div>
        <p id='copyright'>
          Copyright © 2022-2024 Jind. All Rights Reserved.
        </p>
      </div>
      {
        emailStatusModalOpen &&
          <Modal message={emailStatusModalMessage} />
      }
    </div>
  );
}